import React, { useState } from 'react';
import {
  Box,
  Button,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Paper,
  CircularProgress,
  Modal,
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const API_URL = "http://65.108.61.99:8000";

function App() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [processedImage, setProcessedImage] = useState(null);
  const [modelType, setModelType] = useState('pipes');
  const [itemCount, setItemCount] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOriginalModalOpen, setIsOriginalModalOpen] = useState(false);

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      setPreviewUrl(URL.createObjectURL(file));
      setProcessedImage(null);
      setItemCount(null);
      setError(null);
    }
  };

  const handleModelTypeChange = (event) => {
    setModelType(event.target.value);
  };

  const handleSubmit = async () => {
    if (!selectedFile) {
      setError('Please select an image first');
      return;
    }

    setLoading(true);
    setError(null);

    const formData = new FormData();
    formData.append('file', selectedFile);
    formData.append('model_type', modelType);

    try {
      // Make the request without axios's automatic transformation
      const response = await fetch(`${API_URL}/process-image`, {
        method: 'POST',
        body: formData,
      });

      // Print the full response for debugging
      console.log('Response:', response);
      // Get the count from headers
      const count = response.headers.get('x-item-count'.toLowerCase());
      console.log('Count from header:', count);
      setItemCount(count ? parseInt(count, 10) : 0);

      // Get the image blob
      const blob = await response.blob();
      setProcessedImage(URL.createObjectURL(blob));
    } catch (err) {
      setError('Error processing image. Please try again.');
      console.error('Error:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = (event) => {
    if (event.target.tagName !== 'IMG') {
      setIsModalOpen(false);
    }
  };

  const handleOpenOriginalModal = () => {
    setIsOriginalModalOpen(true);
  };

  const handleCloseOriginalModal = (event) => {
    if (event.target.tagName !== 'IMG') {
      setIsOriginalModalOpen(false);
    }
  };

  return (
    <Container maxWidth={false} sx={{ py: 4, px: { xs: 2, sm: 4 } }}>
      <Box
  sx={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  }}
>
  {/* Contenedor del texto */}
  <Box
    sx={{
      flex: 1,
      textAlign: 'center',
    }}
  >
    <Typography
      variant="h4"
      component="h1"
      gutterBottom
      align="center"
    >
      Item Counter
    </Typography>
  </Box>

  {/* Logo alineado a la derecha */}
  <Box
    component="img"
    src="/sisua_logo.png"
    alt="Company Logo"
    sx={{
      height: 50,
      position: 'absolute',
      right: 0,
      marginBottom: 3
    }}
  />
</Box>


      {/* Controls Section */}
      <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
        <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap', alignItems: 'center' }}>
          <FormControl sx={{ minWidth: 200, flex: 1 }}>
            <InputLabel>Model Type</InputLabel>
            <Select
              value={modelType}
              onChange={handleModelTypeChange}
              label="Model Type"
            >
              <MenuItem value="pipes">Pipes</MenuItem>
              <MenuItem value="plates">Plates</MenuItem>
              <MenuItem value="connectors">Connectors</MenuItem>
            </Select>
          </FormControl>

          <Box sx={{ flex: 1, minWidth: 200 }}>
            <input
              accept="image/*"
              type="file"
              id="image-upload"
              style={{ display: 'none' }}
              onChange={handleFileSelect}
            />
            <label htmlFor="image-upload" style={{ width: '100%' }}>
              <Button
                variant="contained"
                component="span"
                startIcon={<CloudUploadIcon />}
                fullWidth
              >
                Upload Image
              </Button>
            </label>
          </Box>

          <Box sx={{ flex: 1, minWidth: 200 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={loading || !selectedFile}
              fullWidth
            >
              {loading ? <CircularProgress size={24} /> : 'Process Image'}
            </Button>
          </Box>

          {itemCount !== null && (
            <Box sx={{ flex: 1, minWidth: 200 }}>
              <Paper
                elevation={2}
                sx={{
                  p: 2,
                  backgroundColor: '#f5f5f5',
                  width: '100%'
                }}
              >
                <Typography variant="h5" color="primary" align="center">
                  Item Count: {itemCount}
                </Typography>
              </Paper>
            </Box>
          )}
        </Box>

        {error && (
          <Typography color="error" align="center" sx={{ mt: 2 }}>
            {error}
          </Typography>
        )}
      </Paper>

      {/* Images Section */}
      {(previewUrl || processedImage) && (
        <Paper elevation={3} sx={{ p: 3 }}>
          <Box sx={{ display: 'flex', gap: 3, flexWrap: { xs: 'wrap', md: 'nowrap' } }}>
            {/* Original Image */}
            {previewUrl && (
              <Box sx={{ flex: 1, minWidth: 0 }}>
                <Paper elevation={2} sx={{ p: 2 }} onClick={handleOpenOriginalModal}>
                  <Typography variant="h6" gutterBottom align="center">
                    Original Image
                  </Typography>
                  <Box sx={{
                    height: 'calc(100vh - 300px)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    overflow: 'hidden',
                  }}>
                    <img
                      src={previewUrl}
                      alt="Preview"
                      style={{
                        maxWidth: '100%',
                        maxHeight: '100%',
                        objectFit: 'contain',
                      }}
                    />
                  </Box>
                </Paper>
              </Box>
            )}

            {/* Processed Image */}
            {processedImage && (
              <Box sx={{ flex: 1, minWidth: 0 }}>
                <Paper elevation={2} sx={{ p: 2 }} onClick={handleOpenModal}>
                  <Typography variant="h6" gutterBottom align="center">
                    Processed Image
                  </Typography>
                  <Box sx={{
                    height: 'calc(100vh - 300px)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    overflow: 'hidden',
                  }}>
                    <img
                      src={processedImage}
                      alt="Processed"
                      style={{
                        maxWidth: '100%',
                        maxHeight: '100%',
                        objectFit: 'contain',
                      }}
                    />
                  </Box>
                </Paper>
              </Box>
            )}
          </Box>
        </Paper>
      )}

      {/* Modals remain the same */}
      <Modal open={isOriginalModalOpen} onClose={handleCloseOriginalModal}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
          }}
          onClick={handleCloseOriginalModal}
        >
          <img
            src={previewUrl}
            alt="Original Full Size"
            style={{
              maxWidth: '90%',
              maxHeight: '90%',
              objectFit: 'contain',
            }}
          />
        </Box>
      </Modal>

      <Modal open={isModalOpen} onClose={handleCloseModal}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
          }}
          onClick={handleCloseModal}
        >
          <img
            src={processedImage}
            alt="Processed Full Size"
            style={{
              maxWidth: '90%',
              maxHeight: '90%',
              objectFit: 'contain',
            }}
          />
        </Box>
      </Modal>
    </Container>
  );
}

export default App;